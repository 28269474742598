import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { CategoryHeading } from "../SimpleComponents/CategoryHeading/CategoryHeading";
import styles from "./sidebar.module.scss";
import { TagButton } from "../Buttons/TagButton/TagButton";
// import { SideArticle } from "../SideArticle/SideArticle";
//{ starArticles, tags, retainedArticles }
const Sidebar = ({ tags }) => {
  return (
    <motion.div className={styles.container}>
      {/*HIDDEN TILL BACKEND IS CARETED*/}
      {/* <motion.div className={styles.star}>
        <CategoryHeading text="Meistgelegene Artikel" />
        <motion.div className={styles.list}>
          {starArticles.map((s, idx) => (
            <SideArticle key={idx} {...s} />
          ))}
        </motion.div>
      </motion.div> */}
      {tags.length > 0 && (
        <motion.div className={styles.tags}>
          <CategoryHeading text="Kategorien" />
          <motion.div className={styles.list}>
            {tags.map((tag, index) => {
              const tagClick = (e) => {
                e.stopPropagation();
                const link = e.target.querySelector("a");
                if (link) {
                  link.click();
                }
              };
              return (
                <TagButton
                  key={index}
                  index={index}
                  title={
                    <Link to={`/wissen/tag/${tag.slug}`}>{tag.title}</Link>
                  }
                  opacityMode={true}
                  onClick={tagClick}
                />
              );
            })}
          </motion.div>
        </motion.div>
      )}
      {/* HIDDEN TILL BACKEND WILL BE CREATED*/}
      {/* <motion.div className={styles.retained}>
        <CategoryHeading text="Einbehaltene" />
        <motion.div className={styles.list}>
          {retainedArticles.map((r, idx) => (
            <SideArticle key={idx} {...r} />
          ))}
        </motion.div>
      </motion.div> */}
    </motion.div>
  );
};

Sidebar.propTypes = {
  starArticles: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  retainedArticles: PropTypes.arrayOf(PropTypes.object),
};

Sidebar.defaultProps = {
  starArticles: [
    {
      slug: "acb-sdf-xd",
      title: "Tipps & Tools - leitfaden für online-meetings",
      isBookmark: false,
    },
    {
      slug: "acb-sdf-xd",
      title:
        "SAP SAC und EPPM: Dynamik und Geschwindigkeit für das controlling",
      isBookmark: true,
    },
    {
      slug: "acb-sdf-xd",
      title: "Glossar - Fachbegriffe rund ums Project",
      isBookmark: false,
    },
    {
      slug: "acb-sdf-xd",
      title: "Interview - was ist beim testen wichtig?",
      isBookmark: true,
    },
  ],
  tags: [
    { title: "Interview", id: 0, slug: "" },
    { title: "Newsletter", id: 0, slug: "" },
    { title: "Newsletter", id: 0, slug: "" },
    { title: "SAP", slug: "" },
    { title: "Newsletter", is: 0, slug: "" },
  ],
  retainedArticles: [
    {
      slug: "acb-sdf-xd",
      title: "Tipps & Tools - leitfaden für online-meetings",
      isBookmark: false,
    },
    {
      slug: "acb-sdf-xd",
      title:
        "SAP SAC und EPPM: Dynamik und Geschwindigkeit für das controlling",
      isBookmark: true,
    },
    {
      slug: "acb-sdf-xd",
      title: "Glossar - Fachbegriffe rund ums Project",
      isBookmark: false,
    },
    {
      slug: "acb-sdf-xd",
      title: "Interview - was ist beim testen wichtig?",
      isBookmark: true,
    },
  ],
};

export { Sidebar };
