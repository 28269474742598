import { useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./list.module.scss";
import { ListItem } from "../ListItem/ListItem";
import constants from "./constants";

const List = ({ items, submenuVisible, startLevel }) => {
  const [visibleSubmenu, setVisibleSubmenu] = useState(-1);

  return (
    submenuVisible && (
      <motion.ul
        className={classNames(
          styles.submenu,
          startLevel ? styles.startLvl : ""
        )}
        variants={constants.ANIMATION.menu.variants}
        initial={"start"}
        animate={submenuVisible ? "end" : "none"}
        exit={"start"}
        transition={constants.ANIMATION.menu.transition(0)}
      >
        {items.map(
          (item, key) =>
            submenuVisible && (
              <motion.div
                key={key}
                variants={constants.ANIMATION.menuItem.variants}
                initial={"start"}
                animate={submenuVisible ? "end" : "none"}
                transition={constants.ANIMATION.menuItem.transition(
                  (submenuVisible ? key : items.length - key) *
                    constants.MENU_ITEM_DELAY
                )}
              >
                <ListItem
                  title={item.title}
                  link={item.link}
                  submenu={item.submenu}
                  index={key}
                  onClick={setVisibleSubmenu}
                  visibleSubmenu={visibleSubmenu}
                />
              </motion.div>
            )
        )}
      </motion.ul>
    )
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  submenuVisible: PropTypes.bool,
  startLevel: PropTypes.bool,
};

List.defaultProps = {
  submenuVisible: false,
  startLevel: false,
};

export { List };
