import global from "../../constants/global"

const DEFAULT_VALUE = ''

const FIELDS = [
    { name: "username", type: "text", placeholder: "Email", default: "" }  
]

const SUCCESS_MESSAGE = `Ihr Passwort wurde erfolgreich zurückgesetzt. Ein neues Passwort wurde an die von Ihnen hinterlegte E-Mail-Adresse verschickt.`

const BUTTON_NAME = 'Zurücksetzen'

const LINKS = [
    `Haben Sie noch kein Benutzerkonto? <a href="/register">Registrieren</a>`,
    `oder gehen Sie zurück zur <a href="/login"> Anmeldeseite</a>`
]

const HEADER_TYPE = global.HEADER_TYPES[2];

const FOOTER_TYPE = global.FOOTER_TYPES[1];

export default {
    DEFAULT_VALUE,
    FIELDS,
    SUCCESS_MESSAGE,
    BUTTON_NAME,
    LINKS,
    HEADER_TYPE,
    FOOTER_TYPE
}