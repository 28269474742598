import images from "../../../assets/images"
import environment from "../../../constants/environment"
import animation from "../../../constants/animation"

// eslint-disable-next-line no-undef
// const ICONS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].TAG

// eslint-disable-next-line no-undef
const VERSION = environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST]
.TAG 

export const PUBLISHED_TITLE = "Publizierte Artikel"
//Published articles

export const NO_PUBLISHED_YET = "Sie haben noch keine Artikel veröffentlicht"
//You don't have any published articles yet

const MINIMAL_BLACK_PANCIL = {
    src: '',
    // src:  ICONS && images[`minimalBlackPancil${ICONS}`] ? images[`minimalBlackPancil${ICONS}`]: images.minimalBlackPancilukhd,
    alt: "pancil"
}

const EDIT_BUTTON = {
    src: images.guidline[`edit_${VERSION}`],
    alt: 'edit post'
}

const DELETE_BUTTON = {
    src: images.guidline[`trash_icon_${VERSION}`],
    alt: 'delete post'
}

const EDIT_ADDRESS = '/posts/edit'

const DELETE_BTN = 'Löschen'

const CANSEL_BTN = 'Überspringen'

const EDIT_HINT_TEXT = 'Bearbeiten'

const DELETE_HINT_TEXT = 'Löschen'


const ANIMATION = {items: animation.FADEIN_DOWN_APPEARING(0.3)}

const ANIMATION_DELAY_INDEX = 0.1

const POPUP_DELETE_TITLE = 'Löschen eines Artikels'

const POPUP_MESSAGE = 'Wollen Sie den Artikel wirklich löschen?'

export default {
    PUBLISHED_TITLE,
    NO_PUBLISHED_YET,
    MINIMAL_BLACK_PANCIL,
    EDIT_BUTTON,
    DELETE_BUTTON,
    EDIT_ADDRESS,
    DELETE_BTN,
    CANSEL_BTN,
    EDIT_HINT_TEXT,
    DELETE_HINT_TEXT,
    ANIMATION,
    ANIMATION_DELAY_INDEX,
    POPUP_DELETE_TITLE,
    POPUP_MESSAGE
}