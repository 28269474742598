import { Preloader } from "../../shared/components/Preloader/Preloader";
import constants from "./constants";
import styles from "./style.module.scss";

export const NotAvaliblePage = ({ title = "", subtitle = "" }) => {

  return (

    <Preloader className={constants.THEME_CLASS}>
      <h1
        className={styles.mobileTitle}
        dangerouslySetInnerHTML={{
          __html: title ? title : constants.TITLE,
        }}
      />
      <div
        className={styles.mobileDescription}
        dangerouslySetInnerHTML={{
          __html: subtitle ? subtitle : constants.SUBTITLE,
        }}
      />
    </Preloader>
  );
};
