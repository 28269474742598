import images from "../../../assets/images";
import animation from "../../../constants/animation";
import global from "../../../constants/global";

const TAG = global.envTAG

const LOGOS = {
  egk: [{ src: images.egkFooterLogo, alt: "logo-egk", width: 271 }],
  zdi: [ {src: images.LogoZdi, alt: "logo-zdi", width: 270 }, { src: images.LogoUkhd, alt: "logo-ukhd", width: 271 }],
};



const STR_TITLE = "Impressum & Datenschuz";
const STR_COPYRIGHT = "©2024 ontron GmbH";

const ANIMATIONS = {
  logoUkhd: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 1, 0) },
  logoZdi: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 1, 0.5) },
  strTitle: { ...animation.FADEIN_APPEARING_VIEWPORT(0, -50, 1, 0) },
  strCopyright: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 50, 1, 0) },
};

const FOOTER_TYPES = global.FOOTER_TYPES

export default {
  LOGOS,
  STR_TITLE,
  STR_COPYRIGHT,
  ANIMATIONS,
  FOOTER_TYPES,
  TAG
};
