import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style.module.scss";

const Container = ({ children, className, fluid }) => {
  return (
    <div className={classNames(styles.container, className, fluid ? styles.fluid : '')}>{children}</div>
  );
};

Container.propTypes = {
  classNames: PropTypes.string,
  fluid: PropTypes.bool,
};

Container.defaultProps = {
  classNames: "",
  fluid: false,
};

export { Container };
