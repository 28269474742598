import images from "../../../assets/images";
import environment from "../../../constants/environment";

const NOPAGE_IMG_DEPARTMENT = {
  src: images.noPageImgDepartment,
  alt: "404 page",
};

// const THERE_COLOR = environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG ? `${environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG}-theme` : 'default-theme';


const NOPAGE_IMG_EGK = {
  src: images.noPageImgEGK,
  alt: "404 page",
};

const NOPAGE_IMG_UKHD = {
  src: images.noPageImgUKHD,
  alt: "404 page",
};

const THEMES_LIST = {
  'egk': NOPAGE_IMG_EGK,
  'zdi': NOPAGE_IMG_UKHD

}

const CURRENT_VER =
  // eslint-disable-next-line no-undef
  environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG ? THEMES_LIST[environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG] : THEMES_LIST['zdi'];

export default {
  // CURRENT_VER,
  NOPAGE_IMG_DEPARTMENT,
  CURRENT_VER
};
