import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { UnavailablePage } from "../../../pages/UnavailablePage/UnavailablePage";
// import { HomePage } from "../../../pages/HomePage/HomePage";
import { PostsListPage } from "../../../pages/PostsListPage/PostsListPage";
import { PostPage } from "../../../pages/PostPage/PostPage";
import { FaqPage } from "../../../pages/FaqPage/FaqPage";
import { LoginPage } from "../../../pages/LoginPage/LoginPage";
import { RegisterPage } from "../../../pages/RegisterPage/RegisterPage";
import { ResetPasswordPage } from "../../../pages/ResetPasswordPage/ResetPasswordPage";
import { NotAvaliblePage } from "../../../pages/NotAvaliblePage/NotAvaliblePage";
import { ProfilePage } from "../../../pages/ProfilePage/ProfilePage";

const Router = ({ setActiveMenuItem, setHeaderType, setFooterType }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoints = 1092;

  useEffect(() => {
    const handleResizeWindow = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const chooseRouteGroup = (groups) => {
    const defaultRoute = groups[0].routes;
    if (Array.isArray(groups) && groups.length) {
      const chosenRoutesGroup = groups.reduce((result, group) => {
        if (group.conidtion) {
          return group.routes;
        }
        return result;
      }, defaultRoute);
      return chosenRoutesGroup;
    }
    return defaultRoute;
  };

  const groupList = [
    {
      conidtion: false,
      routes: (
        <Route
          exact
          path="*"
          element={
            <UnavailablePage
              setHeaderType={setHeaderType}
              setActiveMenuItem={setActiveMenuItem}
            />
          }
        />
      ),
    },
    {
      conidtion: true,
      routes: (
        <>
          <Route
            exact
            path="*"
            element={
              <UnavailablePage
                setHeaderType={setHeaderType}
                setActiveMenuItem={setActiveMenuItem}
              />
            }
          />
          <Route
            exact
            path="/"
            element={
              // <HomePage
              //   setActiveMenuItem={setActiveMenuItem}
              //   setHeaderType={setHeaderType}
              // />
              <PostsListPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <FaqPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          {/* <Route
            exact
            path="/wissen"
            element={
              <PostsListPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          /> */}
          <Route
            exact
            path="/wissen/:slug"
            element={
              <PostPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />
          <Route
            exact
            path="/wissen/tag/:tag"
            element={
              <PostsListPage
                setActiveMenuItem={setActiveMenuItem}
                setHeaderType={setHeaderType}
              />
            }
          />

          <Route
            exact
            path="/login"
            element={
              <LoginPage
                setHeaderType={setHeaderType}
                setFooterType={setFooterType}
              />
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <RegisterPage
                setHeaderType={setHeaderType}
                setFooterType={setFooterType}
              />
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <ResetPasswordPage
                setHeaderType={setHeaderType}
                setFooterType={setFooterType}
              />
            }
          />
          <Route
            exact
            path="/profile"
            element={<ProfilePage setHeaderType={setHeaderType} />}
          />
        </>
      ),
    },
    {
      conidtion: width <= breakpoints,
      routes: (
        <Route
          exact
          path="*"
          element={
            <NotAvaliblePage
            // setHeaderType={setHeaderType}
            // setActiveMenuItem={setActiveMenuItem}
            />
          }
        />
      ),
    },
  ];

  return <Routes>{chooseRouteGroup(groupList)}</Routes>;
};

Router.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func.isRequired,
};

Router.defaultProps = {
  setActiveMenuItem: () => {},
  setHeaderType: () => {},
};

export { Router };
