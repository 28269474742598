import LogoUkhd from "./logo-ukhd.png";
import LogoZdi from "./logo-zdi.png";
import IconAddBookmarkzdi from "./icon-addbookmark.svg";
import IconBookmarkedzdi from "./icon-bookmarked.svg";

import IconInfo from "./icon-info.png";
import IconArrowRight from "./icon-arrow-right.png";
import IconLikeBlue from "./icon-like-blue.svg";
import IconLikeGrey from "./icon-like-grey.svg";
// import IconBookmarkPassive from "./icon-like-fill-white.svg";
import IconLikeFillGrey from "./icon-like-fill-grey.svg";
// import IconBookmarkActivezdi from "./icon-like-fill-blue.svg";
import IconComment from "./icon-comment.svg";
import IconShare from "./icon-share.svg";
import noPageImgEGK from "./NoPage_EGK.png"
import noPageImgUKHD from "./NoPage_UKHD.png"
import guidline from "./guideline"
import faviconegk from "./favicon_egk.ico"
import faviconzdi from "./favicon_zdi.ico"
import egkFooterLogo from "./egk_footer_logo.svg"

export default {
  IconAddBookmarkzdi,
  IconBookmarkedzdi,
  IconInfo,
  LogoUkhd,
  LogoZdi,
  IconArrowRight,
  IconLikeBlue,
  IconLikeGrey,
  // IconBookmarkPassive,
  IconLikeFillGrey,
  // IconBookmarkActivezdi,
  IconComment,
  IconShare,
  guidline,
  noPageImgEGK,
  noPageImgUKHD,
  faviconegk,
  faviconzdi,
  egkFooterLogo
};
