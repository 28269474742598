export const SHOW_COLLAPSE = {
  opacity: 1,
  height: "auto",
};

export const HIDE_COLLAPSE = {
  opacity: 0,
  height: "0",
};

export default {
  SHOW_COLLAPSE,
  HIDE_COLLAPSE
}