import global from "../../constants/global"

const PASSWORD = 'password'
const DEFAULT_VALUE = ''


const FIELDS = [
    {
      name: "username",
      type: "text",
      default: "",
      placeholder: "Benutzername",
      tooltip: "Der Benutzername wird zum Signieren von Artikeln verwendet"
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email",
      default: ""
    },
    {
      name: PASSWORD,
      type: PASSWORD,
      default: "",
      placeholder: "Passwort",
    },
    {
      name: "repeatpassword",
      type: PASSWORD,
      default: "",
      placeholder: `Passwort wiederholen`,
    }
]

const SUCCESS_MESSAGE = 'Das Benutzerkonto wurde angelegt. Bitte kehren Sie zur Anmeldeseite zurück'

const LINKS = [
  `Haben Sie Ihr<a href="/reset-password"> Passwort vergessen?</a>`,
  `Oder gehen Sie zurück zur <a href="/login">Anmeldeseite</a>`
]

const BUTTON_NAME = 'Anmelden'

const HEADER_TYPE = global.HEADER_TYPES[2];

const FOOTER_TYPE = global.FOOTER_TYPES[1]

export default {
  PASSWORD,
  DEFAULT_VALUE,
  FIELDS,
  SUCCESS_MESSAGE,
  LINKS,
  BUTTON_NAME,
  HEADER_TYPE,
  FOOTER_TYPE
}