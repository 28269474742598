import { useDispatch } from "react-redux";
import { hide, show } from "../../features/menu.data";

const useMenu = () => {
  const dispatcher = useDispatch();
  
  
  const resetMenu = () => {
    dispatcher(hide());
    setTimeout(()=>{
        dispatcher(show());
    }, 100)
  };

  const showMenu = () => {
    dispatcher(show());
  };

  return {
    resetMenu,
    showMenu,
  };
};

export default useMenu;
