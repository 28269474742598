import images from "../../../assets/images";
import animation from "../../../constants/animation";

const ARROW_ICON = { src: images.IconArrowRight, alt: "icon-arrow-right" };

const ANIMATIONS = {
  arrow: { ...animation.ROTATE("90deg", 0.2) },
  listContainer: { ...animation.DOWN_APPEARING(0.2, -30) },
  listItem: {
    // hover: { ...animation.HOVER_BACKGROUND_CHANGE(0.1) },
    active: { ...animation.FADEIN_DOWN_APPEARING(0.2, -30) },
  },
};

const ITEM_DELAY = 0.1;

export default {
  ARROW_ICON,
  ANIMATIONS,
  ITEM_DELAY,
};
