import constants from "./constants";
import styles from "./nopagesectionimage.module.scss";

export const NoPageStaticImage = ({ text = "", title = "" }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={
          constants.CURRENT_VER.src
        }
        alt={
          constants.CURRENT_VER.alt
        }
      />
      <div
        className={`${styles.textContainer} ${styles[[constants.CURRENT_VER]]}`}
      >
        <h2>{title}</h2>
        <h3>{text}</h3>
      </div>
    </div>
  );
};
