import images from "../assets/images";
import environment from "./environment";

const envTAG =
  // eslint-disable-next-line no-undef
  environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST]?.TAG

console.log(envTAG)

const FAVICON = envTAG ? images[`favicon${envTAG}`] : images.faviconzdi

const FILE_TYPES_FOR_IMAGES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/webp',
]


const PAGES_IDs = [
  "home",
  "wissen",
  "projekte-im-programm",
  "faq",
  "agile-methodik",
  "undefined"
];

const SUPPORT_HEADERS = ["information", "error", "warning"];

const DEFAULT_ARTICLES_CATEGORY_ID = 1;

const ICON_BOOKMARKS = {
  unbookmarked: { src: images[`IconAddBookmark${envTAG}`], alt: "icon-add-bookmark" },
  bookmarked: { src: images[`IconBookmarked${envTAG}`], alt: "icon-bookmarked" },
};
const ICON_LIKE = {
  active: {
    src: images[`IconBookmarkActive${envTAG}`], alt: "icon-like"
  },
  passive: {
    src: images[`IconBookmarkPassive`], alt: "icon-like"
  }
}
const ICON_SHARE = {
  src: images.IconShare,
  alt: "icon-share",
};
const ICON_COMMENT = {
  src: images.IconComment,
  alt: "icon-comment",
};

const HEADER_TYPES = ["big", "tiny", "noheader"];

const FOOTER_TYPES = ["standart", "nofooter"];

const LOADING = "Herunterladen...";
const TXT_SHARE = "Artikel per e-mail teilen";

const MONTH_LIST = [
  'Januar',
  'April',
  'Februar',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
  'März'
]


export default {
  PAGES_IDs,
  SUPPORT_HEADERS,
  DEFAULT_ARTICLES_CATEGORY_ID,
  ICON_BOOKMARKS,
  ICON_LIKE,
  ICON_SHARE,
  ICON_COMMENT,
  HEADER_TYPES,
  LOADING,
  FOOTER_TYPES,
  TXT_SHARE,
  MONTH_LIST,
  FAVICON,
  envTAG,
  FILE_TYPES_FOR_IMAGES
};
