import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";

import { MenuButton } from "../../Buttons/MenuButton/MenuButton";
import styles from "./menu.module.scss";

const Menu = ({ items, activeId }) => {
  const setActivity = (menuItem) => {
    if (menuItem && activeId) {
      return menuItem.id && menuItem.id == activeId;
    }
    return false;
  };

  return (
    <motion.div className={classNames(styles.container)}>
      {items.length > 0 &&
        items.map((item, index) => (
          <motion.div key={index} className={styles.button__container}>
            <MenuButton
              title={item.linkTitle ? item.linkTitle : item.title}
              link={item.link}
              active={setActivity(item)}
              submenu={item.submenu}
              index={index}
            />
          </motion.div>
        ))}
    </motion.div>
  );
};

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeId: PropTypes.string,
};

Menu.defaultProps = {
  items: [],
  activeId: false,
};

export { Menu };
