import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SaveToFavoritesButton } from "../../Buttons/SaveToFavoritesButton/SaveToFavoritesButton";
import { TagButton } from "../../Buttons/TagButton/TagButton";
import utils from "../../../../utils";
import styles from "./cardtext.module.scss";


const CardTextArea = ({
  title,
  tagList,
  author,
  postId,
  userId,
  data,
  preview,
  onClick,
  bookmarked
}) => {
  return (
    <motion.div className={classNames(styles.textContainer)}>
      {author && (
        <motion.div className={styles.authorContainer}>
          <motion.div className={styles.autorImageContainer}>
            <img src={author.avatar.src} alt={author.avatar.alt} />
          </motion.div>
          <motion.div className={styles.autorName}>
            <span>{author.name}</span>
          </motion.div>
        </motion.div>
      )}
      <motion.div className={styles.titleContainer}>
        <motion.h3 dangerouslySetInnerHTML={{ __html: title }} onClick={onClick}/>
      </motion.div>
      <motion.div
        className={styles.previewText}
        dangerouslySetInnerHTML={{ __html: preview }}
      />
      <motion.div className={styles.actions}>
        {data && <motion.div className={styles.data}>{utils.posts.converDate(data)}</motion.div>}

        {tagList.length > 0 && (
          <motion.div className={styles.tagsContaioner}>
            {tagList.map((tag, index) => {
              const tagClick = (e) => {
                e.stopPropagation();
                const link = e.target.querySelector("a");
                if (link) {
                  link.click();
                }
              };
              return (
                <motion.div key={index} className={styles.buttonItem}>
                  <TagButton
                    key={index}
                    title={
                      <Link to={`/wissen/tag/${tag.slug}`}>{tag.title}</Link>
                    }
                    onClick={tagClick}
                    opacityMode={false}
                    index={index}
                    // mode={"normal"}
                    // rounded={true}
                  />
                </motion.div>
              );
            })}
          </motion.div>
        )}
        {/* //userId != 0 && */}
        { postId != 0 && (
          <motion.div className={styles.saveToFavContainer}>
            <SaveToFavoritesButton userId={userId} postId={postId} bookmarked={bookmarked}/>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};

CardTextArea.propTypes = {
  postId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  author: PropTypes.oneOfType([
    PropTypes.shape({
      avatar: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  title: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  tagList: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.string,
  bookmarked: PropTypes.bool
};

CardTextArea.defaultProps = {
  author: "",
  tagList: [],
  userId: undefined,
  postId: undefined,
  bookmarked: false
};

export { CardTextArea };
