const decodeHtmlCharCodes = (str) => {
  return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    return String.fromCharCode(charCode);
  });
};

const convertStringToArray = (str) => {
  return JSON.parse(str).map(Number);
};

const removeTags = (text) => {
  return text.replace(/<(.|\n)*?>/g, '')
}

export default {
  decodeHtmlCharCodes,
  convertStringToArray,
  removeTags
};
