// import environment from "../../constants/environment"
import global from "../../constants/global";

const THEME_CLASS = global.envTAG? `${global.envTAG}-theme` : 'default-theme';

const TITLE = 'Seite nicht verfügbar'
//Page is not available
const SUBTITLE = 'Die Website ist in der mobilen Version nicht verfügbar'
//The site is not available on the mobile version

export default {
    TITLE,
    SUBTITLE,
    THEME_CLASS
}