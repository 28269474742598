import global from "../../../../constants/global";
import animation from "../../../../constants/animation";
import images from "../../../../assets/images"

const ANIMATION = { ...animation.FADEIN_LEFT_APPEARANCE() };

const SUBTITLE_ANIMATION_DELAY = 0.5;

const HEADER_TYPES = global.HEADER_TYPES;

const USER_ICON = {
  src: images.guidline.user_24_light,
  alt: "user icon"
}


export default {
  ANIMATION,
  SUBTITLE_ANIMATION_DELAY,
  HEADER_TYPES,
  USER_ICON
};
