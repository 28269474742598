import animation from "../../constants/animation";
import global from "../../constants/global";

const PAGE_ID = global.PAGES_IDs[1];
const HEADER_TYPE = global.HEADER_TYPES[1];

const ANIMATIONS = {
  thumbnail: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.8, 0) },
  title: { ...animation.FADEIN_APPEARING_VIEWPORT(0, -150, 1, 0) },
  bottomTitle: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 150, 1, 0) },
  preview: { ...animation.FADEIN_APPEARING_VIEWPORT(-350, 0, 1, 0) },
  info: { ...animation.FADEIN_APPEARING_VIEWPORT(0, -100, 0.8, 0) },
};

const TXT_SHARE = global.TXT_SHARE;

export default {
  PAGE_ID,
  HEADER_TYPE,
  ANIMATIONS,
  TXT_SHARE,
};
