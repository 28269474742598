import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./categoryHeading.module.scss"


const CategoryHeading = ({ text, className }) => {
  return <motion.h4 className={classNames(styles.title, className)}>{text}</motion.h4>;
};

CategoryHeading.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

CategoryHeading.defaultProps = {
    className: ""
}

export { CategoryHeading };
