import api from "./apiQueryUtils";
import env from "./globalVarsProcess";
import text from "./textProcess"
import user from "./userData"
import mediafiles from "./mediafilesHandler"
import posts from "./postsHandler"

export default {
  api,
  env,
  text,
  user,
  mediafiles,
  posts
};
