import global from "../../constants/global";

const PAGE_ID = global.PAGES_IDs[1];

const DEFAULT_ARTICLES_CATEGORY_ID = global.DEFAULT_ARTICLES_CATEGORY_ID;

const ARTICLES_COUNT_PER_PAGE = 6;

const HEADER_TYPE = global.HEADER_TYPES[0];

export default {
  PAGE_ID,
  DEFAULT_ARTICLES_CATEGORY_ID,
  ARTICLES_COUNT_PER_PAGE,
  HEADER_TYPE,
};
