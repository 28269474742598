import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { List } from "../../List/List/List";
// import useMenu from "../../../../hooks/menu/menu";
// import constants from "./contants";
import styles from "./menuButton.module.scss";

const MenuButton = ({ title, link, submenu, active }) => {
  const { visibilityStatus } = useSelector((state) => state.menu);

  const [submenuVisible, setSubmenuVisible] = useState(false);

  const showSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  };

  useEffect(() => {
    setSubmenuVisible(false);
  }, [visibilityStatus]);

  return (
    // <AnimatePresence>
    <motion.div
      className={classNames(styles.container, active ? styles.active : "")}
      // variants={constants.ANIMATION.variants}
      // initial={"start"}
      // animate={"end"}
      // transition={constants.ANIMATION.transition(
      //   index * constants.ANIMATION_DELAY_INDEX
      // )}
    >
      <div className={styles.underline}></div>
      <motion.div
        className={classNames(
          styles.link__container,
          submenu.length > 0 ? styles.with_submenu : "",
          submenuVisible && submenu.length > 0 ? styles.active : ""
        )}
        onClick={submenu.length > 0 ? showSubmenu : () => {}}
      >
        {submenu.length > 0 ? (
          <>
            <motion.div className={styles.icon}></motion.div>
            <span className={styles.link}>{title}</span>
          </>
        ) : (
          <Link className={styles.link} to={link}>
            {title}
          </Link>
        )}
      </motion.div>
      {submenu.length > 0 && (
        <List
          items={submenu}
          submenuVisible={submenuVisible}
          startLevel={true}
        />
      )}
    </motion.div>
    // </AnimatePresence>
  );
};

MenuButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.bool,
  index: PropTypes.number,
  setResetAll: PropTypes.func,
};

MenuButton.defaultProps = {
  active: false,
  submenu: [],
  index: 0,
};
export { MenuButton };
