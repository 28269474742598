'use client'

import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./share.module.scss";
import constants from "./constants";

const Share = ({ text, className }) => {
  return (
    <motion.a
      href={constants.SHERE_HREF_VALUE}
      className={classNames(styles.container, className)}
      // {...constants.ANIMATIONS.container}
    >
      <motion.p className={classNames(styles.text)}>{text}</motion.p>
      {console.log(constants.ICON_SHARE)}
      <motion.img
        className={classNames(styles.icon)}
        {...constants.ICON_SHARE}
      />
    </motion.a>
  );
};

Share.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  email: PropTypes.string,
};

Share.defaultProps = {
  text: "",
  className: "",
  email: "",
};

export { Share };
