import animation from "../../../../constants/animation";
import images from "../../../../assets/images"
import environment from "../../../../constants/environment";

const ICON_SHARE =
{
  // eslint-disable-next-line no-undef
  src: environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG && images.guidline[`share_icon_${environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG}`] ? images.guidline[`share_icon_${environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG}`] : images['share_icon_zdi'],
  alt: 'share'
}

const BLOG_TITLE = 'EGK Blog'

const MAIL_TITILE = `Interessanter%20Artikel%20auf%20${BLOG_TITLE}`

const MAIL_BODY = `Guten%20Tag,%0D%0Aich%20teile%20diese%20Veröffentlichung%20auf%20${BLOG_TITLE}%20mit%20Ihnen:%0D%0A%0D%0A${window.location.href}%0D%0A%0D%0A==================================%0D%0ADiese%20E-Mail%20wurde%20automatisch%20generiert.`

const SHERE_HREF_VALUE=`mailto:?subject=${MAIL_TITILE}&amp&body=${MAIL_BODY}`

const ANIMATIONS = {
  container: { ...animation.FADEIN_APPEARING_VIEWPORT(0, 0, 0.5, 0.3) },
};

export default {
  ICON_SHARE,
  ANIMATIONS,
  SHERE_HREF_VALUE
};
