import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
// import constants from "./constants";
import styles from "./tagButton.module.scss";
const TagButton = ({ title, onClick, opacityMode, className }) => {
  return (
    <motion.div
      onClick={onClick}
      // variants={constants.ANIMATION.variants}
      // initial={"start"}
      // animate={"end"}
      // transition={constants.ANIMATION.transition(
      //   index * constants.ANIMATION_DELAY_INDEX
      // )}
      className={classNames(
        styles.container,
        "buttons-settings",
        opacityMode ? styles.opacityMode : "",
        className
      )}
    >
      {title}
    </motion.div>
  );
};

TagButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  opacityMode: PropTypes.bool,
  className: PropTypes.string,
  index: PropTypes.number,
};

TagButton.defualtProps = {
  onClick: () => {},
  opacityMode: false,
  className: "",
  index: 0,
};

export { TagButton };
