import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import utils from "../utils";
import api from "../api/server.api";
import crud from "../api/crud";
import { setMessage, clearMessage } from "./message";
import constants from "../constants/global";

const initialState = {
  user: {},
  isLoggedIn: utils.user.getToken(),
  message: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await api.custom.post(
        crud.custom.LOGIN,
        {
          username,
          password,
        },
        {
          "Content-Type": "application/json",
        }
      );
      utils.user.setToken(response.data.token);
      thunkAPI.dispatch(clearMessage());
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      // const errorText = error.response
      //   ? error.response.data.message
      //   : error.message
      const errorText = constants.NOT_CORRECT_LOGIN_ODER_PASSWORD;
      thunkAPI.dispatch(setMessage(errorText));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, email, password }, thunkAPI) => {
    try {
      const response = await api.custom.post(crud.custom.REGISTER, {
        username,
        email,
        password,
      });
      thunkAPI.dispatch(clearMessage());
      return response.data;
    } catch (error) {
      const errorText = error.response
        ? error.response.data.message
        : error.message;
      thunkAPI.dispatch(setMessage(errorText));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async ({ username }, thunkAPI) => {
    try {
      const response = await api.custom.post(crud.custom.RESET_PASWORD, {
        login: username,
      });
      thunkAPI.dispatch(clearMessage());
      return response.data;
    } catch (error) {
      const errorText = error.response
        ? error.response.data.message
        : error.message;
      thunkAPI.dispatch(setMessage(errorText));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  utils.user.resetToken();
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [register.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [register.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload ? action.payload : null;
      if (state.user) {
        delete state.user.token;
        utils.user.setID(state.user.user_id);
        utils.user.setAvatar(state.user.avatar);
        utils.user.setUserData(state.user);
      }
    },
    [login.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      utils.user.resetAllData();
    },
  },
});

const { reducer } = userSlice;
export const { setUser } = userSlice.actions;
export default reducer;
