import global from "../../constants/global";
import environment from "../../constants/environment";


// eslint-disable-next-line no-undef
const THERE_COLOR = environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG ? `${environment.VARIABLES[process.env.REACT_APP_API_ENV][environment.HOST].TAG}-theme` : 'default-theme';


const MENU_ITEMS = [
  // {
  //   title: "EGK Lernblog",
  //   subtitle: "Was Sie wissen sollten",
  //   link: "/",
  //   linkTitle: "Lernen",
  //   id: global.PAGES_IDs[0],
  // },
  {
    title: "EGK Lernblog",
    subtitle: "Was Sie wissen sollten",
    link: "/",
    linkTitle: "Lernen",
    id: global.PAGES_IDs[1],
  },
  // {
  //   title: "Projekte im Programm",
  //   subtitle: "",
  //   link: "/projekte-im-programm",
  //   id: global.PAGES_IDs[2],
  //   submenu: [
  //     {
  //       title: "Button title",
  //       link: "/button-title",
  //       submenu: [
  //         {
  //           title: "Button title",
  //           link: "/button-title",
  //         },
  //         {
  //           title: "Button title",
  //           link: "/button-title",
  //         },
  //         {
  //           title: "Button title",
  //           link: "/button-title",
  //           submenu: [
  //             {
  //               title: "Button title",
  //               link: "/button-title",
  //             },
  //             {
  //               title: "Button title",
  //               link: "/button-title",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: "Button title",
  //       link: "/button-title",
  //     },
  //     {
  //       title: "Button title",
  //       link: "/button-title",
  //     },
  //   ],
  // },
  {
    title: "FAQ",
    link: "/faq",
    subtitle: "",
    id: global.PAGES_IDs[3],
  },
  {
    title: "",
    link: "/404",
    subtitle: "",
    id: global.PAGES_IDs[5],
  },

];

const HEADER_TYPES = global.HEADER_TYPES;
const FOOTER_TYPES = global.FOOTER_TYPES;

export default {
  MENU_ITEMS,
  HEADER_TYPES,
  FOOTER_TYPES,
  THERE_COLOR
};
