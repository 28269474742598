import global from "../../constants/global";

const PAGE_ID = global.PAGES_IDs[3];

const HEADER_TYPE = global.HEADER_TYPES[0];

const TEMPORARY_QUESTIONS = [
  {
    title: 'Gibt es künftig noch einen Newsletter zum Projekt?',
    answer: 'Mit dem Projektblog möchten wir auch kurzfristig zu aktuellen Themen und Entwicklungen informieren. Er bietet die Möglichkeit, sich jederzeit Hintergrundwissen zu Prozessen, Prozessentwicklungen und SAP-Anwendungen anzueignen. Der Blog ist außerdem eine Einladung zu Beteiligung und Austausch: Über die Kommentarfelder oder das Kontaktformular können Sie konkrete Inhalte kommentieren und Anregungen oder Feedback einreichen.'
  },
  {
    title: 'Darf ich auch einmal einen Artikel schreiben?',
    answer: 'Der Autor oder die Autorin sind direkt im Artikel zu finden. Namentlich nicht gekennzeichnete Artikel wurden über das Changemanagement erstellt.'
  },
  {
    title: 'Können wir nicht auch mal etwas schreiben über…?',
    answer: 'Neuer Content wird mindestens einmal pro Woche veröffentlicht, in der Regel sogar noch häufiger. Es lohnt sich also, regelmäßig vorbeizuschauen.'
  },
  {
    title: 'Wie oft wird etwas veröffentlicht?',
    answer: 'Ideen für Artikel sind herzlich willkommen. Bitte wenden Sie sich direkt an: Markus Erhart,<br>markus.erhart@ontron.de.'
  },
  {
    title: 'Wer erstellt die Inhalte?',
    answer: 'Das würde uns freuen, wir beraten Sie hierzu gerne: Markus Erhart,<br>markus.erhart@ontron.de'
  },
  {
    title: 'Wieso gibt es einen Blog für das Projekt?',
    answer: 'Mit dem Blog haben wir das Newsletterformat in ein interaktiveres Format umgewandelt, das Interessierte jederzeit nutzen können – der Blog bietet nicht nur punktuell einen Mehrwert, sondern ist als steig wachsende Sammlung nützlicher und hilfreicher Inhalte zu verstehen.'
  }
]



export default {
  PAGE_ID,
  HEADER_TYPE,
  TEMPORARY_QUESTIONS
};
