import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    visibilityStatus: true
};

const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        hide: (state) => {
            state.visibilityStatus = false
        },
        show: (state) => {
            state.visibilityStatus = true
        },
    },
});


const { reducer, actions } = menuSlice
export const { hide, show } = actions
export default reducer