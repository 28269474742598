import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import constants from "./constants";
import styles from "./standartButton.module.scss";

const StandartButton = ({ title, onClick, mode, imageBefore, imageAfter, rounded, variants, classes }) => {
  const checkExistingMode = (mode) => {
    return constants.MODE_LIST.includes(mode);
  };
  return (
    <motion.div
      onClick={onClick}
      variants={variants}
      className={classNames(
        styles.container,
        classes,
        styles[
          `container__${
            checkExistingMode(mode) ? mode : constants.MODE_LIST[0]
          }`
        ],
        rounded ? styles.rounded : ''
      )}
    >
      {imageBefore && imageBefore.src && (
        <div className={styles.contentLine}>
          <img
            className={classNames(styles.image__before, styles.image)}
            src={imageBefore.src}
            alt={imageBefore.alt}
          />
        </div>
      )}
      <div className={styles.contentLine}>
        <span>{title}</span>
      </div>
      {imageAfter && imageAfter.src && (
        <div className={styles.contentLine}>
          <img
            className={classNames(styles.image__after, styles.image)}
            src={imageAfter.src}
            alt={imageAfter.alt}
          />
        </div>
      )}
    </motion.div>
  );
};

StandartButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(constants.MODE_LIST),
  imageBefore: PropTypes.shape({src: PropTypes.string, alt: PropTypes.string}),
  imageAfter: PropTypes.shape({src: PropTypes.string, alt: PropTypes.string}),
  rounded: PropTypes.bool,
  variants: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  classes: PropTypes.string
};

StandartButton.defaultProps = {
  onClick: () => {},
  mode: "normal",
  imageBefore: null,
  imageAfter: null,
  rounded: false,
  variants: [],
  classes: ''
};

export { StandartButton };
