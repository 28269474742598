import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container } from "../../components/Markup/Container/Container";
import { Row } from "../../components/Markup/Row/Row";
import { Col } from "../../components/Markup/Col/Col";
import constants from "./constants";
import styles from "./footer.module.scss";

const Footer = ({ type }) => {
  return (
    type != "nofooter" && (
      <section className={styles.container}>
      
        {constants.LOGOS[constants.TAG] &&
          constants.LOGOS[constants.TAG].length > 1 && (
            <Container>
              <Link to={"/"} className={styles.logo}>
                <motion.img
                  {...constants.LOGOS[constants.TAG][1]}
                  // {...constants.ANIMATIONS.logoUkhd}
                />
              </Link>
            </Container>
          )}
        <Container className={styles.zdiContainer}>
          <Row>
            <Col numb={6}>
              <Link to={"/"} className={styles.logo}>
                <motion.img
                  {...constants.LOGOS[constants.TAG][0]}
                  // {...constants.ANIMATIONS.logoZdi}
                />
              </Link>
            </Col>
            <Col numb={6}>
              <motion.div className={styles.titles}>
                <Link to="/">
                  <motion.h3>{constants.STR_TITLE}</motion.h3>
                </Link>
                <motion.h3>{constants.STR_COPYRIGHT}</motion.h3>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

Footer.propTypes = {
  type: PropTypes.oneOf(constants.FOOTER_TYPES),
};

Footer.deaultProps = {
  type: constants.FOOTER_TYPES[0],
};

export { Footer };
