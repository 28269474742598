import global from "../../constants/global";

const HEADER_TYPE = global.HEADER_TYPES[1];

const TITLE = "404"

const DESCRIPTION = "Die Seite wurde nicht gefunden"

export default {
  HEADER_TYPE,
  TITLE,
  DESCRIPTION
};
