import { motion } from "framer-motion";
import { useEffect, useState } from "react";
// import { useDispatch} from "react-redux";
import classNames from "classnames";
// import { Tooltip } from "antd";
import { Formik, Form } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { StandartButton } from "../../Buttons/StandartButton/StandartButton";
import { BaseSubmitButton } from "../../Buttons/BaseSubmitButton/BaseSubmitButton";
// import { PasswordField } from "../../_partials/PasswordField/PasswordField";
// import { resetPasswordSchems, editUserFromSchems } from "../../../../schemes";
// import { logout } from "../../../../features/auth.slice";
// import { setUser } from "../../../../features/auth.slice";
import { FormField } from "../../TextFields/FormField/FormField";
// import useLoadContent from "../../../hooks/loadContent/loadContent";
import useFillHint from "../../../../hooks/fillHint/fillHint";
// import api from "../../../../api/server.api";
// import crud from "../../../../api/crud";
import utils from "../../../../utils";
import global from "../../../../constants/global";
import constants from "./constants";
import styles from "./userEditForm.module.scss";


// export const UserEditForm = ({ user, className = "" }) => {
  export const UserEditForm = ({ className = "" }) => {
  const navigator = useNavigate()
//   const dispatch = useDispatch();
  const { mouseLeave, mouseOver } = useFillHint(constants.HINT_AVATAR_EDIT_BTN);
//   const userState = useSelector((state) => state.user);
  const [oldPassOpened, setOldPassOpened] = useState(false);
  const [newPassOpened, setNewPassOpened] = useState(false);
  const [resetPassOpened, setResetNewPassOpened] = useState(false);
  // const [userNameVal, steUserNameVal] = useState(
  //   user.display_name ? user.display_name : ""
  // );
  // const [emailVal, setEmailVal] = useState(
  //   user.user_email ? user.user_email : ""
  // );
  const [avatar, setAvatar] = useState({
    src: constants.USER_AVATAR.src,
    id: null,
    alt: "avatar",
    file: null,
    blockId: `avatar-${utils.user.getUserID()}`,
  });
//   const { prossessFileLoading, generalError: AvatarError } = useLoadContent();
// const {generalError: AvatarError } = useLoadContent();

  const [passwordGeneralError, setPasswordGeneralError] = useState({
    error: false,
    message: "",
  });
//   const [userEditGeneralError, setUserEditGeneralError] = useState({
//     error: false,
//     message: "",
//   });
//   const [logoutStatus, setLogoutStatus] = useState("");
  const userDataFormId = uuid().slice(0, 16);
  const [filesTypes] = useState({
    image: global.FILE_TYPES_FOR_IMAGES,
  });

  const changePassportVisibility = (eyeButton, visibilityEyeFunction) => {
    visibilityEyeFunction(!eyeButton);
  };

  const addNewAvatar = () => {
    const inputItem = document
      .getElementById(userDataFormId)
      .querySelector("#avatar-image");
    console.log(inputItem);
    inputItem.click();
  };

  const logoutFromAllDevices = () => {
    navigator("/login")
    return <Navigate to="/login"/>
    // setLogoutStatus(constants.LOGOUT_SATUS);
    // utils.user.resetAllData();
    // dispatch(logout());
  };

  const resetPassword = () => { 
//   const resetPassword = (values) => {
//     const { oldPassword, newPassword } = values;
//     const userID = utils.user.getUserID();

//     const sendToServer = async () => {
//       await utils.api.postData(
//         api.custom,
//         crud.custom.USER_NEW_PASSWORD,
//         callback,
//         {
//           id: userID,
//           old_pass: oldPassword,
//           new_pass: newPassword,
//         },
//         {},
//         callback
//       );
//     };

//     setPasswordGeneralError({
//       ...passwordGeneralError,
//       message: constants.LOADING,
//       error: false,
//     });

//     const callback = (data) => {
//       setPasswordGeneralError({
//         ...passwordGeneralError,
//         message: "",
//         error: false,
//       });
//       console.log(data);
//       if ((data.data && data.message) || typeof data == "string") {
//         setPasswordGeneralError({
//           ...passwordGeneralError,
//           message: data.message ? data.message : data,
//           error: data.data && data.data.status == "200" ? false : true,
//         });
//       }
//     };
//     sendToServer();
   };

   const updateUserData = () => {
//    const updateUserData = (values) => {
//     const { username, email } = values;
//     const userID = utils.user.getUserID();

//     const sendToServer = async (data = null) => {
//       if (avatar.id) {
//         utils.mediafiles.deleteMedifile(avatar.id, () => {}, errorHander);
//       }
//       if (data) {
//         setAvatar({ ...avatar, src: data.source_url, id: data.id });
//       }
//       await utils.api.postData(
//         api.custom,
//         crud.custom.USER_CHANGE,
//         callback,
//         {
//           ID: userID,
//           display_name: username,
//           user_email: email,
//           image: data ? data.source_url : avatar.src,
//           image_ID: data && data.id ? data.id : null,
//         },
//         {},
//         errorHander
//       );
//     };
//     setUserEditGeneralError({
//       ...userEditGeneralError,
//       message: constants.LOADING,
//       error: false,
//     });
//     const callback = (data) => {
//       if (data.message) {
//         setUserEditGeneralError({
//           ...userEditGeneralError,
//           message: data.message,
//           error: false,
//         });

//         if (data.data) {
//           dispatch(
//             // setUser({
//             //   ...userState.user,
//             //   id: utils.user.getUserID(),
//             //   token: utils.user.getToken(),
//             //   user_email: data.data.user_email,
//             //   user_nicename: data.data.display_name,
//             //   avatar: data.data.image,
//             // })
//           );
//           setAvatar({
//             ...avatar,
//             src: data["data"]["image"],
//             id: data["data"]["image_id"],
//           });
//           utils.user.setAvatar({
//             src: data["data"]["image"],
//             id: data["data"]["image_id"],
//           });
//           steUserNameVal(data["data"]["display_name"]);
//           setEmailVal(data["data"]["user_email"]);
//           utils.user.setUserData(data["data"]);
//           const avatarIcon = document.querySelector("#profile-icon-avatar");
//           if (avatarIcon) {
//             avatarIcon.setAttribute("src", data["data"]["image"]);
//             avatarIcon.setAttribute("alt", constants.USER_AVATAR.alt);
//           }
//         }
//       }
     };

//     const errorHander = (data) => {
//       if (data.message || typeof data === "string") {
//         setUserEditGeneralError({
//           ...userEditGeneralError,
//           message: data.message ? data.message : data,
//           error: true,
//         });
//       }
//     };
//     if (avatar.file) {
//       utils.mediafiles.addMediafile(
//         { ...avatar, src: avatar.file },
//         sendToServer,
//         errorHander
//       );
//     } else {
//       sendToServer();
//     }
//   };

  const changeAvatarHandler = (e) => {
    if (e.target && e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
    //   const callback = (e) => {
        if (e.target.result) {
          setAvatar({
            ...avatar,
            src: e.target.result,
            alt: constants.USER_AVATAR.alt,
            file: imageFile,
          });
        }
    //   };
    //   prossessFileLoading(imageFile, callback);
    }
  };

  useEffect(() => {
    const avatarImage = utils.user.getAvatar()?.src
      ? utils.user.getAvatar().src
      : utils.user.getAvatar();

    const result = (img) => {
      setAvatar({
        src: img,
        id: null,
        alt: "avatar",
        file: null,
        blockId: `avatar-${utils.user.getUserID()}`,
      });
    };

    utils.mediafiles.checkImageLoading(
      avatarImage,
      constants.USER_AVATAR.src,
      result
    );
  }, []);

  return (
    <motion.div
      className={classNames(
        styles.userFormContainer,
        className,
        "userEditForm"
      )}
    >
      <Formik
        initialValues={{
          // username: userNameVal,
          // email: emailVal,
          username: 'Blog user',
          email: 'blog.user@email.com',
        }}
        // validationSchema={editUserFromSchems}
        onSubmit={updateUserData}
      >
        {(props) => {
          const onChnageUsername = (e) => {
            props.handleChange(e);
            // steUserNameVal(e.target.value);
          };

          const onChangeEmail = (e) => {
            props.handleChange(e);
            // setEmailVal(e.target.value);
          };

          return (
            <Form id={userDataFormId}>
              <motion.div className={styles.avatraConatiner}>
                <motion.div className={styles.avatarImageContainer}>
                  <img
                    className="avatar"
                    src={avatar.src}
                    alt={avatar.alt}
                    loading="lazy"
                    id={
                      utils.user.getAvatar()?.id
                        ? `avatar-${utils.user.getAvatar().id}`
                        : "avatar"
                    }
                  />
                </motion.div>
                <motion.div
                  onMouseEnter={mouseOver}
                  onMouseLeave={mouseLeave}
                  className={styles.changeAvatarBtn}
                  onClick={addNewAvatar}
                >
                  <img
                    src={constants.PENCIL_ICON.src}
                    alt={constants.PENCIL_ICON.alt}
                    loading="lazy"
                  />
                </motion.div>
              </motion.div>
              {/* {AvatarError && (
                <motion.div
                  className={classNames("error")}
                  dangerouslySetInnerHTML={{
                    __html: AvatarError,
                  }}
                />
              )} */}
              <motion.div>
                <motion.div title={constants.USER_TOOLTIP} placement="bottom">
                  {/* <Field
                    name="username"
                    type="text"
                    id="username"
                    onChange={onChnageUsername}
                    placeholder={constants.USER_PLACEHOLDER}
                    value={userNameVal}
                    className={styles.userFromsInputs}
                  /> */}
                  <FormField
                    type="text"
                    name="username"
                    id="username"
                    classes={styles.topFild}
                    onChange={onChnageUsername}
                  />
                  <label className={styles.inputsSigns} htmlFor="username">
                    {constants.USER_PLACEHOLDER}
                  </label>
                  {props.errors.username && (
                    <motion.div className="error">
                      <span>{props.errors.username}</span>
                    </motion.div>
                  )}
                </motion.div>
              </motion.div>
              <motion.div>
                {/* <Field
                  name={constants.EMAIL_PLACEHOLDER}
                  type="text"
                  id={constants.EMAIL_PLACEHOLDER}
                  onChange={onChangeEmail}
                  placeholder={constants.EMAIL_PLACEHOLDER}
                  value={emailVal}
                  className={styles.userFromsInputs}
                /> */}
                <FormField
                  type="text"
                  name={"email"}
                  id={constants.EMAIL_PLACEHOLDER}
                  classes={styles.topFild}
                  onChange={onChangeEmail}
                />
                <label
                  className={styles.inputsSigns}
                  htmlFor={constants.EMAIL_PLACEHOLDER}
                >
                  {constants.EMAIL_PLACEHOLDER}
                </label>
                {props.errors.email && (
                  <motion.div className="error">
                    <span>{props.errors.email}</span>
                  </motion.div>
                )}
              </motion.div>
              <input
                type="file"
                id="avatar-image"
                accept={Object.keys(filesTypes)
                  .map((typeKind) => filesTypes[typeKind].join())
                  .join()}
                onChange={changeAvatarHandler}
                className={styles.invisible}
              />
              {/* <StandartButton
                title={constants.SAVE_BUTTON}
                mode={"outlined"}
                classes={styles.submitButton}
              /> */}
              <BaseSubmitButton
                className={styles.submitButton}
                mode={"outlined"}
                title={constants.SAVE_BUTTON}
              />
              {/* {userEditGeneralError.message && (
                <motion.div
                  className={classNames(
                    "error",
                    userEditGeneralError.error ? "" : "success"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: userEditGeneralError.message,
                  }}
                />
              )} */}
            </Form>
          );
        }}
      </Formik>
      <motion.div className={styles.formTitleContainer}>
        <motion.h3 className={styles.formTitle}>
          {constants.FORM_TITLE}
        </motion.h3>
      </motion.div>
      <Formik
        initialValues={{
          oldPassword: constants.DEFAULT_VALUE,
          newPassword: constants.DEFAULT_VALUE,
          repeatNewPassword: constants.DEFAULT_VALUE,
        }}
        // validationSchema={resetPasswordSchems}
        onSubmit={resetPassword}
      >
        {(props) => {
          const changeData = (e) => {
            props.handleChange(e);
            setPasswordGeneralError("");
          };

          // const fields = [
          //   {
          //     type: constants.PASWORD,
          //     name: "oldPassword",
          //     placeholder: constants.OLD_PASSWORD,
          //     value: props.values.oldPassword,
          //   },
          //   {
          //     type: constants.PASWORD,
          //     name: "newPassword",
          //     placeholder: constants.NEW_PASSWORD,
          //     value: props.values.newPassword,
          //   },
          //   {
          //     type: constants.PASWORD,
          //     name: "repeatNewPassword",
          //     placeholder: constants.REPEAT_PASSWORD,
          //     value: props.values.repeatNewPassword,
          //   },
          // ];

          return (
            <Form>
              <FormField
                type={oldPassOpened ? "text" : "password"}
                name={"oldPassword"}
                id={constants.OLD_PASSWORD_FIELD}
                placeholder={constants.OLD_PASSWORD}
                classes={styles.topFild}
                onChange={changeData}
              >
                <motion.div
                  className={classNames(
                    styles.passwordFielsIcon,
                    !oldPassOpened ? styles.closed : ""
                  )}
                  onClick={() => {
                    changePassportVisibility(oldPassOpened, setOldPassOpened);
                  }}
                >
                  <motion.img
                    src={constants.EYE_OPENED.src}
                    alt={constants.EYE_OPENED.alt}
                    className={classNames(styles.eye, styles.eyeOpened)}
                  />
                  <motion.img
                    src={constants.EYE_CLOSED.src}
                    alt={constants.EYE_CLOSED.alt}
                    className={classNames(styles.eye, styles.eyeClosed)}
                  />
                </motion.div>
              </FormField>
              <FormField
                type={newPassOpened ? "text" : "password"}
                name={constants.NEW_PASSWORD_FIELD}
                id={constants.NEW_PASSWORD_FIELD}
                placeholder={constants.NEW_PASSWORD}
                classes={styles.topFild}
              >
                <motion.div
                  className={classNames(
                    styles.passwordFielsIcon,
                    !newPassOpened ? styles.closed : ""
                  )}
                  onClick={() => {
                    changePassportVisibility(newPassOpened, setNewPassOpened);
                  }}
                >
                  <motion.img
                    src={constants.EYE_OPENED.src}
                    alt={constants.EYE_OPENED.alt}
                    className={classNames(styles.eye, styles.eyeOpened)}
                  />
                  <motion.img
                    src={constants.EYE_CLOSED.src}
                    alt={constants.EYE_CLOSED.alt}
                    className={classNames(styles.eye, styles.eyeClosed)}
                  />
                </motion.div>
              </FormField>
              <FormField
                type={resetPassOpened ? "text" : "password"}
                name={constants.RESET_PASSWORD}
                id={constants.RESET_PASSWORD}
                placeholder={constants.REPEAT_PASSWORD}
                classes={styles.topFild}
              >
                <motion.div
                  className={classNames(
                    styles.passwordFielsIcon,
                    !resetPassOpened ? styles.closed : ""
                  )}
                  onClick={() => {
                    changePassportVisibility(
                      resetPassOpened,
                      setResetNewPassOpened
                    );
                  }}
                >
                  <motion.img
                    src={constants.EYE_OPENED.src}
                    alt={constants.EYE_OPENED.alt}
                    className={classNames(styles.eye, styles.eyeOpened)}
                  />
                  <motion.img
                    src={constants.EYE_CLOSED.src}
                    alt={constants.EYE_CLOSED.alt}
                    className={classNames(styles.eye, styles.eyeClosed)}
                  />
                </motion.div>
              </FormField>
              {/* {fields.length > 0 &&
                fields.map((field, index) => (
                  <FormField
                  type="text"
                  name={constants.EMAIL_PLACEHOLDER}
                  id={constants.EMAIL_PLACEHOLDER}
                  classes={styles.topFild}
                />
                  // <PasswordField
                  //   field={field}
                  //   index={index}
                  //   props={props}
                  //   onChange={changeData}
                  //   value={field.value}
                  // />
                ))} */}
              <BaseSubmitButton
                mode={"outlined"}
                className={classNames(
                  styles.submitButton,
                  styles.bottomButtonArea
                )}
                title={constants.RESET_PASSWORD_BUTTON}
              />
              {/* <StandartButton
                title={constants.RESET_PASSWORD_BUTTON}
                mode={"outlined"}
                classes={classNames(styles.submitButton, styles.bottomForm)}
              /> */}
              {passwordGeneralError.message && (
                <motion.div
                  className={classNames(
                    "error",
                    passwordGeneralError.error ? "" : "success"
                  )}
                >
                  {passwordGeneralError.message}
                </motion.div>
              )}
            </Form>
          );
        }}
      </Formik>
      <motion.div className={styles.logoutFromAllDevisesBtn}>
        {/* <BaseSubmitButton
          className={styles.submitButton}
          mode={"filled"}
          onClick={logoutFromAllDevices}
          title={constants.LOG_OUT_IN_ALL_DEVICES}
        /> */}
        <StandartButton
          title={constants.LOG_OUT_IN_ALL_DEVICES}
          mode={"filled"}
          classes={classNames(styles.submitButton, styles.filledSubmitButton)}
          onClick={logoutFromAllDevices}
        />
        {/* <motion.div>{logoutStatus}</motion.div> */}
      </motion.div>
    </motion.div>
  );
};
