import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./standartButton.module.scss";

const StandartButtons = ({ title, onClick, mode }) => {
  return (
    <motion.div
      onClick={onClick}
      className={classNames(
        styles.container,
        mode === "normal"
          ? styles.container__normal
          : mode === "filled"
          ? styles.container__filled
          : styles.container__outlined
      )}
    >
      {title}
    </motion.div>
  );
};

StandartButtons.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf(["normal", "filled", "outlined"]),
};

StandartButtons.defaultProps = {
  onClick: () => {},
  mode: "normal",
};

export { StandartButtons };
