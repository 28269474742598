
import global from "../constants/global";

const converDate = (date) => {
    try {
        const newDate = new Date(date);
        return `${newDate.getDate()} ${global.MONTH_LIST[newDate.getMonth()-1]} ${newDate.getFullYear()}`
    } catch (e) {
        return date
    }
}

const postOutputInTubs = (postList, styledClass) => {
    const list = Array.from(postList)
    const userForm = document
      .querySelector('.userEditForm')
      .getBoundingClientRect()
      const tabs = document.querySelector('.tabs-container')
    const navigationHeight = tabs.getBoundingClientRect().height
    const marginBottomOfCard = (cardStyle) => {
      return Number(cardStyle.match(/\d/g).join(''))
    }
    let summary = navigationHeight + marginBottomOfCard((tabs.currentStyle || window.getComputedStyle(tabs)).marginBottom)
    console.log(summary)
    console.log(userForm.height)
    list.forEach((post, index) => {
      const card = post.getBoundingClientRect()
      const marginBottom = marginBottomOfCard(
        (post.currentStyle || window.getComputedStyle(post)).marginBottom,
      )
      const currentCardHieght = card.height + marginBottom
      if (summary > userForm.height && index > 0) {
        if (
          list &&
          !post.classList.contains(styledClass) &&
          userForm.height - summary + marginBottom > 0 &&
          list[index - 1] &&
          list[index - 1].classList &&
          list[index - 1].classList.contains(styledClass)
        ) {
          post.setAttribute(
            'style',
            `margin-top: ${userForm.height - summary + marginBottom + navigationHeight
            }px;`,
          )
          summary += userForm.height - summary + marginBottom + navigationHeight
        }
        post.classList.add(styledClass)
      } else {
        console.log(index)
        post.classList.remove(styledClass)
      }
      summary += currentCardHieght
      console.log(summary)
    })
  }


export default {
    converDate,
    postOutputInTubs
}