import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./col.module.scss";
classNames;

const Col = ({ numb, children, md = 0 }) => {
  const getClassName = () => {
    switch (numb) {
      case 1:
        return styles.col__1;
      case 2:
        return styles.col__2;
      case 3:
        return styles.col__3;
      case 4:
        return styles.col__4;
      case 6:
        return styles.col__6;
      case 8:
        return styles.col__8;
      case 9:
        return styles.col__9;
      case 10:
        return styles.col__10;
      case 11:
        return styles.col__11;
      case 12:
        return styles.col__11;
      default:
        return styles.col__4;
    }
  };
  return (
    <div
      className={classNames(
        getClassName(),
        styles.col,
        md ? styles[`col_sm__${md}`] : ""
      )}
    >
      {children}
    </div>
  );
};

Col.propTypes = {
  numb: PropTypes.number,
  md: PropTypes.number,
};

export { Col };
