import global from "../../constants/global";

const HEADER_TYPE = global.HEADER_TYPES[1];

const USR = {}

const TABS = [
    {
        key: 1,
        //label: "Gespeicherte Entwürfe",
        label: "Gespeicherte Artikel"
    },
    // {
    //     key: 2,
    //     //label: "Veröffentlichte Artikel"
    //     label: "Bevorzugte Artikel"
    // }
]

const DEFAULT_ARTICLES_CATEGORY_ID = global.DEFAULT_ARTICLES_CATEGORY_ID;

export default {
    USR,
    HEADER_TYPE,
    TABS,
    DEFAULT_ARTICLES_CATEGORY_ID
}