import { useEffect, useRef, useState } from "react";
// import { useDispatch } from "react-redux";
import {
  motion,
  // AnimatePresence,
  useAnimation,
  useInView,
} from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Container } from "../../Markup/Container/Container";
import { Row } from "../../Markup/Row/Row";
import { Menu } from "../Menu/Menu";
import { Col } from "../../Markup/Col/Col";
import { SimpleButton } from "../../../components/Buttons/SimpleButton/SimpleButton";
import { PopupPanel } from "../../Popup/PopupPanel/PopupPanel";

import constants from "./constants";
import styles from "./header.module.scss";

const Header = ({ menuItems, activeMenuItem, title, subtitle, type }) => {
  const controlls = useAnimation();
  const header = useRef(null);
  const menuRef = useRef(null);
  const paddingTopRef = useRef(null);
  const isInView = useInView(header, { once: true });
  const [popupVisibility, setPopUpVisibility] = useState(false);

  const mouseOver = () => {
    setPopUpVisibility(true);
  };

  const mouseLeave = () => {
    setPopUpVisibility(false);
  };

  const invokeAnimation = (inViewStatus) => {
    controlls.set("start");
    if (inViewStatus) {
      controlls.start("end");
    }
  };

  const onScroll = () => {
    if (menuRef  && menuRef.current) {
      if (window.scrollY > menuRef.current.getBoundingClientRect().top) {
        menuRef.current.style.position = "fixed";
        paddingTopRef.current.style.paddingTop = "51px";
      } else {
        menuRef.current.style.position = "relative";
        paddingTopRef.current.style.paddingTop = "0px";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    // setPopUpVisibility(false);
    return () => {
      window.removeEventListener("scroll", onScroll);
      // setPopUpVisibility(false);
    };
  }, []);

  useEffect(() => {
    invokeAnimation(isInView);
  }, [title, isInView]);

  return (
    type != "noheader" && (
      <section className={classNames(styles.container)} ref={header}>
        <motion.div
          className={classNames(
            styles.paddingTop,
            type === constants.HEADER_TYPES[1] && styles.tiny
          )}
          ref={paddingTopRef}
        ></motion.div>
        <div ref={menuRef} className={classNames(styles.menuContainer)}>
          <Container className={styles.markup__container}>
            <Row>
              <Col numb={11}>
                {/* {visibilityStatus && ( */}
                <Menu items={menuItems} activeId={activeMenuItem} />
                {/* )} */}
              </Col>
              <Col numb={1}>
                <SimpleButton
                  title={`<img src="${constants.USER_ICON.src}" alt="profile" className="${styles.userIcon}" id="profile-icon-avatar"/>`}
                  mouseLeave={mouseLeave}
                  mouseOver={mouseOver}
                  classes={styles.userButtonConatiner}
                  internalClasses={styles.userButtonInternalContainer}
                  // variants={constants.CARD_VARIANTS}
                >
                  {popupVisibility && (
                    <PopupPanel
                      hoverFunction={mouseOver}
                      mouseLeaveFunction={mouseLeave}
                      onClick={()=>{ setPopUpVisibility(false)}}
                    />
                  )}
                </SimpleButton>
                {/* <UserButton icon={constants.USER_ICON}/> */}
                {/* <StandartButtons title={"Ausgang"} onClick={logOut}></StandartButtons> */}
              </Col>
            </Row>
          </Container>
        </div>
        <Container
          className={
            type === constants.HEADER_TYPES[1] ? styles.titleHeader : ""
          }
        >
          {title && (
            <Row>
              {/* <AnimatePresence> */}
              <Col numb={12}>
                <motion.h1
                // variants={constants.ANIMATION.variants}
                // initial="start"
                // animate={"end"}
                // transition={constants.ANIMATION.transition(0.5)}
                >
                  {title}
                </motion.h1>
              </Col>
              {/* </AnimatePresence> */}
            </Row>
          )}
          {subtitle && (
            <Row>
              <Col numb={12}>
                {/* <AnimatePresence> */}
                <motion.div
                  // variants={constants.ANIMATION.variants}
                  // initial="start"
                  // exit="start"
                  // animate={controlls}
                  // transition={constants.ANIMATION.transition(
                  //   constants.SUBTITLE_ANIMATION_DELAY
                  // )}
                  className={styles.subtitle}
                >
                  {subtitle}
                </motion.div>
              </Col>
              {/* </AnimatePresence> */}
            </Row>
          )}
        </Container>
        <motion.div
          className={classNames(
            styles.paddingBottom,
            type === constants.HEADER_TYPES[1] && styles.tiny
          )}
        ></motion.div>
      </section>
    )
  );
};

Header.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeMenuItem: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  resetMenu: PropTypes.bool,
  setResetMenu: PropTypes.func,
  type: PropTypes.oneOf(constants.HEADER_TYPES),
};

Header.defaultProps = {
  menuItems: [],
  activeMenuItem: false,
  title: "",
  subtitle: "",
  resetMenu: false,
  setResetMenu: () => {},
  type: constants.HEADER_TYPES[0],
};

export { Header };
