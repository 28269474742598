const NETWORK_ERROR = "Netzwerkfehler";

const SERVER_ERROR = "Serverfehler";

const NO_USER_TOKEN = "Kein Benutzer-Token gefunden";

const EMPTY_ANSWER = "Der Server gab eine leere Antwort zurück";

export default {
  NETWORK_ERROR,
  SERVER_ERROR,
  NO_USER_TOKEN,
  EMPTY_ANSWER,
};
