import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./listItem.module.scss";
import { List } from "../List/List";

const ListItem = ({
  title,
  link,
  submenu,
  index,
  onClick,
  visibleSubmenu,
}) => {
  const selectVisibleMenu = (e) => {
    e.stopPropagation();
    const selectedIndex = visibleSubmenu == index ? -1 : index;
    onClick(selectedIndex);
  };

  return (
    <AnimatePresence>
      <motion.li
        onClick={selectVisibleMenu}
        className={classNames(
          styles.submenu__item,
          submenu && index == visibleSubmenu ? styles.active : ""
        )}
      >
        {submenu && submenu.length > 0 ? (
          <>
            <motion.div className={styles.icon}></motion.div>
            <span className={styles.link} onClick={selectVisibleMenu}>{title}</span>
          </>
        ) : (
          <Link className={styles.link} to={link}>{title}</Link>
        )}
        {submenu && submenu.length > 0 && (
          <List items={submenu} submenuVisible={visibleSubmenu == index} />
        )}
      </motion.li>
    </AnimatePresence>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  onClick: PropTypes.func,
  visibleSubmenu: PropTypes.number,
};

ListItem.defaultProps = {
  submenu: [],
  index: 0,
};

export { ListItem };
