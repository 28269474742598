import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { Navigate, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Col } from "../../shared/components/Markup/Col/Col";
import { UserEditForm } from "../../shared/components/_forms/UserEditForm/UserEditForm";
import { Tabs } from "../../shared/components/Tabs/Tabs";
import { PublishedPostsSection } from "../../shared/sections/PublishedPostsSection/PublishedPostsSection";
import utils from "../../utils";
import crud from "../../api/crud";
import api from "../../api/server.api";
import constants from "./constants";
import styles from "./profilepage.module.scss";

const ProfilePage = ({ setHeaderType }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [tabs, setTabs] = useState(constants.TABS);
  //   const [usr, setUsr] = useState({});
  const [postWithLike, setPostWithLikes] = useState([]);
  // const [savedPosts, setSavedPosts] = useState([]);

  const [tabs, setTabs] = useState(constants.TABS);
  const loadInformation = async () => {
    setIsLoading(true);
    // const userID = utils.user.getUserID();
    // const token = utils.user.getToken();

    // if (!userID || !token) {
    //   navigate("/login");
    //   return <Navigate to="/login" />;
    // }
    try {
      await Promise.all([
        utils.api.getData(
          api.custom,
          `${crud.custom.ARTICLES_LATEST}?category=${constants.DEFAULT_ARTICLES_CATEGORY_ID}&per_page=3`,
          (articles) => {
            console.log(articles);
            if (articles.articles) {
              setPostWithLikes(articles.articles);
              // setSavedPosts(articles);
            }
          }
        ),
        {},
        (e) => {
          console.log(e);
        },
        // utils.api.getData(api.custom, crud.custom.ARTICLE_TAGS, setTagList),
      ]);

      //   await Promise.all(loadFunctionsArray);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInformation();
    setHeaderType(constants.HEADER_TYPE);
  }, []);

  useEffect(() => {
    const newTabs = tabs.map((tab, key)=>{
      if(key == 0 && postWithLike.length){
        return {...tab, child:postWithLike }
      } 
      return {...tab}
    })

    if (postWithLike.length) {
      console.log(postWithLike);
      newTabs[0].child = postWithLike;
      console.log(newTabs)
    }
    setTabs(newTabs);
  }, [postWithLike]);


  useEffect(()=>{
    console.log(tabs)
  }, [tabs])

  return (
    <>
      {isLoading && <Preloader />}
      <motion.section className={styles.profileSection}>
        <Container>
          <Row>
            <Col numb={4}>
              <UserEditForm
                user={constants.USR}
                className={styles.UserEditForm}
              />
            </Col>
            <Col numb={8}>
              <Tabs
                defaultActiveKey={1}
                items={constants.TABS}
                className={styles.userInterfaceTabs}
                isloading={isLoading}
              >
                {tabs?.length > 0 &&
                  tabs.map((tab, key) => (
                    <PublishedPostsSection
                      key={key}
                      posts={tab.child?.length ? tab.child : []}
                      // menuReloader={menuReloader}
                      // canEdit={isModerator}
                      // setPosts={setPostWithLikes}
                      // toolsListExists={isModerator}
                    />
                  ))}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </motion.section>
    </>
  );
};

ProfilePage.propTypes = {
  setHeaderType: PropTypes.func,
};

ProfilePage.defaultProps = {
  setHeaderType: () => {},
};

export { ProfilePage };
