import { motion } from "framer-motion";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { Container } from "../../Markup/Container/Container";
import { BaseSubmitButton } from "../../Buttons/BaseSubmitButton/BaseSubmitButton";
// import { Preloader } from "../../Preloader/Preloader";
import constants from "./constants";
import styles from "./userform.module.scss";
import { useEffect } from "react";
import { FormField } from "../../TextFields/FormField/FormField";

export const UserForm = ({
  fields,
  handler,
  buttonName = "",
  subtitle = "",
  children,
  // verificationSheme,
  succesResult,
  className,
  formClass = "",
  titleClasses = "",
  // loadingInProcess = false,
  setLoadingInProcess = () => {},
}) => {
  const changePassportVisibility = (eyeButton, visibilityEyeFunction) => {
    visibilityEyeFunction(!eyeButton);
  };
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    if (message) {
      setLoadingInProcess(false);
    }
  }, [message]);

  return (
    <>
      {/* {loadingInProcess && (
        <Preloader litenTheme={true}>
          <motion.div>{global.LOADING}</motion.div>
        </Preloader>
      )} */}
      <Container
        className={classNames(
          styles.container,
          styles.darkBlue,
          className,
          constants.THEME_CLASS
        )}
        style={{ backgroundColor: constants.DEFAULT_COLOR }}
      >
        <motion.div className={styles.formContainer}>
          <motion.div className={classNames(styles.fieldsArea, formClass)}>
            <motion.div className={classNames(styles.formTitle, titleClasses)}>
              {constants.TITLE}
            </motion.div>
            <motion.div className={styles.subTitle}>{subtitle}</motion.div>
            <Formik
              initialValues={fields.reduce((res, field) => {
                res[field.name] = field.default;
                return res;
              }, {})}
              // validationSchema={verificationSheme}
              onSubmit={handler}
            >
              {(props) => {
                return (
                  <Form>
                    {fields.length > 0 &&
                      fields.map((field, index) => (
                        <div
                          className={classNames(styles.inputContainer, field.type == "checkbox" ? styles.checkboxChild : '')}
                          key={`field-${index}`}
                        >
                          <FormField
                            id={field.name}
                            name={field.name}
                            type={
                              field.type == "password"
                                ? field.eyeVisibility
                                  ? "text"
                                  : field.type
                                : field.type
                            }
                            placeholder={field.placeholder}
                          >
                            {field.value && (
                              <label
                                className={styles.label}
                                htmlFor={field.name}
                              >
                                {field.value}
                              </label>
                            )}
                            {field.type == "password" && (
                              <motion.div
                                className={classNames(
                                  styles.passwordFielsIcon,
                                  field.eyeVisibility ? styles.closed : ""
                                )}
                                onClick={() => {
                                  changePassportVisibility(
                                    field.eyeVisibility,
                                    field.setEyeVisibility
                                  );
                                }}
                              >
                                <motion.img
                                  src={constants.EYE_OPENED.src}
                                  alt={constants.EYE_OPENED.alt}
                                  className={classNames(
                                    styles.eye,
                                    styles.eyeOpened
                                  )}
                                />
                                <motion.img
                                  src={constants.EYE_CLOSED.src}
                                  alt={constants.EYE_CLOSED.alt}
                                  className={classNames(
                                    styles.eye,
                                    styles.eyeClosed
                                  )}
                                />
                              </motion.div>
                            )}
                          </FormField>

                          {props.errors[field.name] ? (
                            <motion.div
                              className={classNames("error", styles.error)}
                            >
                              <span>{props.errors[field.name]}</span>
                            </motion.div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    <BaseSubmitButton
                      title={buttonName}
                      mode={"filled"}
                      className={styles.formButton}
                    />
                    {message && (
                      <motion.div
                        className={classNames(
                          styles.error,
                          succesResult ? styles.success : ""
                        )}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: message,
                          }}
                        />
                      </motion.div>
                    )}
                  </Form>
                );
              }}
            </Formik>
            <motion.div className={styles.linksArea}>{children}</motion.div>
          </motion.div>
        </motion.div>
      </Container>
    </>
  );
};
