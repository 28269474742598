// import environment from "../../../../constants/environment";
import global from "../../../../constants/global";
import images from "../../../../assets/images";

// eslint-disable-next-line no-undef
const THEME_CLASS =  global.envTAG ? `${global.envTAG}-theme` : 'default-theme';

// eslint-disable-next-line no-undef
// const settings = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]

const ERROR_MESSAGE = 'Etwas ist schiefgelaufen';
const TITLE = 'Schön, dass Sie da sind!';

// const FORM_TITLE =  settings.HOME_LINK_TEXT

// const DEFAULT_COLOR = settings.COLOR

const EYE_OPENED = {
    src: images.guidline.eye_closed_icon,
    alt: 'eye opened'
}

const EYE_CLOSED = {
    src: images.guidline.eye_opened_icon,
    alt: 'eye closed'
}


export default {
    ERROR_MESSAGE,
    TITLE,
    // FORM_TITLE,
    // DEFAULT_COLOR,
    THEME_CLASS,
    EYE_OPENED,
    EYE_CLOSED
}