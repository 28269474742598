import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./simpleButton.module.scss";

const SimpleButton = ({
  title,
  onClick,
  variants,
  mouseOver,
  mouseLeave,
  classes,
  internalClasses,
  children,
}) => {
  return (
    <motion.div className={classes}>
      <motion.div
        className={classNames(styles.container, internalClasses)}
        onClick={onClick}
        variants={variants}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseLeave}
      >
        <span
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </motion.div>
      {children}
    </motion.div>
  );
};

SimpleButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variants: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  mouseOver: PropTypes.func,
  mouseLeave: PropTypes.func,
  classes: PropTypes.string,
  internalClasses: PropTypes.string
};

SimpleButton.defaultProps = {
  onClick: () => {},
  variants: [],
  mouseOver: () => {},
  mouseLeave: () => {},
  classes: "",
  internalClasses: ""
};

export { SimpleButton };
