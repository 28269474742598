import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import constants from "../StandartButton/constants";
import styles from "../StandartButton/standartButton.module.scss";

const BaseSubmitButton = ({
  className,
  title,
  mode,
  rounded
}) => {
  const checkExistingMode = (mode) => {
    return constants.MODE_LIST.includes(mode);
  };

  return (
    <motion.input
      type="submit"
      className={classNames(
        styles.container,
        className,
        styles[
          `container__${
            checkExistingMode(mode) ? mode : constants.MODE_LIST[0]
          }`
        ],
        rounded ? styles.rounded : ""
      )}
      value={title}
    />
  );
};

BaseSubmitButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(constants.MODE_LIST),
  rounded: PropTypes.bool
}

BaseSubmitButton.defaultProps = {
  className: '',
  title: '',
  mode: "normal",
  rounded: false

}

export {BaseSubmitButton}