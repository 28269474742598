import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import styles from "./preloader.module.scss"

const Preloader = ({ className, litenTheme, children }) => {
  return (
    <AnimatePresence initial={false}>
      <motion.div className={classNames(styles.container, litenTheme ? styles.container__litenTheme : '', className)}>
        {children}
        <div className={styles.loader}>
            <div className={styles.loader__outer}></div>
            <div className={styles.loader__inner}></div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

Preloader.propTypes = {
  className: PropTypes.string,
  litenTheme: PropTypes.bool
};

Preloader.defaultProps = {
  classNames: '',
  litenTheme: false
};
export { Preloader };
