import environment from "../constants/environment";


const getEnvValue = (param, alernativeVal = false) => {
    // eslint-disable-next-line no-undef
    const settings = environment.VARIABLES[process.env.REACT_APP_API_ENV]
    if (settings && settings[environment.HOST] && settings[environment.HOST][param]) {
        return settings[environment.HOST][param]
    }
    return alernativeVal;
}

const getHost = () => {
    return getEnvValue('URL_CUSTOM', false)
}

export default {
    getEnvValue,
    getHost
}