import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Sidebar } from "../../shared/components/Sidebar/Sidebar";
// import { Col } from "../../shared/components/Markup/Col/Col";
import { Collapse } from "../../shared/components/Collapse/Collapse";
import useMenu from "../../hooks/menu/menu";
import constants from "./constants";
import utils from "../../utils";
import api from "../../api/server.api";
import crud from "../../api/crud";
import styles from "./faqpage.module.scss";
import { Col } from "../../shared/components/Markup/Col/Col";

const FaqPage = ({ setActiveMenuItem, setHeaderType }) => {
  const { resetMenu } = useMenu();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, seyQuestions] = useState(constants.TEMPORARY_QUESTIONS);
  const [tagList, setTagList] = useState([]);

  const loadInformation = async () => {
    try {
      await Promise.all([
        utils.api.getData(
          api.standart,
          `${crud.standart.QESTIONS}?order=desc&orderby=modified`,
          seyQuestions
        ),
        utils.api.getData(api.custom, crud.custom.ARTICLE_TAGS, setTagList),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetMenu();
    setActiveMenuItem(constants.PAGE_ID);
    setHeaderType(constants.HEADER_TYPE);
    try {
      loadInformation();
    } catch (e) {
      //error helding
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
      <Container className={styles.faqPageConatiner}>
        <Row>
          <Col numb={8}>
            <motion.ul>
              {questions.map((question, index) => (
                <motion.li key={index}>
                  <Collapse
                    animationDelay={constants.ANIMATION_DELAY}
                    title={question.title}
                    key={`question-${index}`}
                    className={styles.questionCollapseContainer}
                  >
                    <motion.div
                      className={styles.collapseText}
                      dangerouslySetInnerHTML={{
                        __html: question.answer,
                      }}
                    />
                  </Collapse>
                </motion.li>
              ))}
            </motion.ul>
            </Col>
            <Col numb={4}>
            <Sidebar tags={tagList} />
            </Col>
        </Row>
      </Container>
    </>
  );
};

FaqPage.propTypes = {
  setActiveMenuItem: PropTypes.func.isRequired,
  setHeaderType: PropTypes.func,
};

FaqPage.defaultProps = { setActiveMenuItem: () => {}, setHeaderType: () => {} };

export { FaqPage };
