import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "../../shared/components/Markup/Container/Container";
import { Row } from "../../shared/components/Markup/Row/Row";
import { Col } from "../../shared/components/Markup/Col/Col";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import {NoPageStaticImage} from "../../shared/components/NoPageStaticImage/NoPageStaticImage"
import constants from "./constants";
// import global from "../../constants/global";

export const UnavailablePage = ({ setHeaderType }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // setActiveMenuItem(global.PAGES_IDs[5])
    setHeaderType(constants.HEADER_TYPE);
    try {
      //server querry
    } catch (e) {
      //error helding
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
      <Container>
        <Row>
          <Col numb={12}>
            <NoPageStaticImage text={constants.DESCRIPTION} title={constants.TITLE}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

UnavailablePage.propTypes = {
  setHeaderType: PropTypes.func,
};

UnavailablePage.defaultProps = {
  setHeaderType: () => {},
};
