const USER_TOKEN_VALIABLE = 'blog-token'
const USER_LOGIN_VARIABLE = 'blog-login'
const USER_PASSWORD_VARIABLE = 'blog-password'
const USER_ID_VARIABLE = 'blog-u-id'
const USER_AVATAR_VARIABLE = 'blog-u-avatar'
const CURRENT_USER = 'blog-user'
const LAST_VISITED_PAGE = 'blog-last-page'

export default  {
    USER_TOKEN_VALIABLE,
    USER_LOGIN_VARIABLE,
    USER_PASSWORD_VARIABLE,
    USER_ID_VARIABLE,
    USER_AVATAR_VARIABLE,
    CURRENT_USER,
    LAST_VISITED_PAGE
}