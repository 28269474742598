import { configureStore } from '@reduxjs/toolkit'
import menu from "../features/menu.data"
import message from "../features/message"
import user from "../features/user.operations"

export const store = configureStore({
    reducer: {
        menu: menu,
        message: message,
        user: user

    }
})