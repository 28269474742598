import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  isShown: false,
  text: '',
  coords: {
    x: 0,
    y: 0,
  }
}

const popUpHintSlice = createSlice({
  name: 'popupHint',
  initialState,
  reducers: {
    show: (state) => {
      state.isShown = true
    },
    hide: (state) => {
      state.isShown = false
      state.text = '';
    },
    setData: (state, action) => {
      state.text = action.payload
    },
    setPosition: (state, action) => {
      state.coords = {
        x:
          action.payload.x
            ? action.payload.x
            : 0,
        y:
          action.payload.y
            ? action.payload.y
            : 0,
      }
    },
  },
})

const { reducer, actions } = popUpHintSlice

export const { show, hide, setData, setPosition } = actions
export default reducer
