//normal
//20x20
import pancil from "./basic/20/icon_20_edit.svg"
import tresh_box from "./basic/20/20_delete.svg"
import plus from "./basic/20/20_add.svg"
import arrow_down from "./basic/20/20_arrow_down.svg"
import arrow_up from "./basic/20/20_arrow_up.svg"
import arrow_left from "./basic/20/20_arrow_left.svg"
import arrow_right from "./basic/20/20_arrow_right.svg"
import download from "./basic/20/icon_20_download.svg"
import copy from "./basic/20/icon_20_copy.svg"
import long_arrow_down from "./basic/20/icon_20_arrow_down.svg"
import long_arrow_up from "./basic/20/icon_20_arrow_up.svg"
import like from "./gray/20/State=enabled.svg"
import like_hover from "./theme_color/20/State=pressed.svg"
import v_icon from "./basic/20/State=enabled (1).svg"
import x_icon from "./basic/20/State=enabled (2).svg"
import smile from "./basic/20/State=enabled (3).svg"
import doggy from "./basic/20/State=enabled (4).svg"
import linkage from "./basic/20/State=enabled (5).svg"
import arrow_right_white from "./white/20/20_arrow_down_white.svg"
import edit_ukhd from "./theme_color/20/icon_20_edit_ukhd.svg"
import edit_egk from "./theme_color/20/icon_20_edit_egk.svg"
import tresh_box_blue from "./basic/20/State=pressed (3).svg"
import like_button from "./gray/20/icon_24_like (1).svg"
import trash_icon_ukhd from "./theme_color/20/State=pressed (5).svg"
import trash_icon_egk from "./theme_color/20/trash_basket_egk.svg"
import like_button_ukhd from "./theme_color/20/icon_like_ukhd.svg"
import like_button_egk from "./theme_color/20/icon_like_egk.svg"
 //24
import image_list from "./basic/24/icon_20_photo_library.svg"
import eye from "./basic/24/24_visibility.svg"
import eye_closed from "./basic/24/24_visibility_off.svg"
import search from "./basic/24/24_search.svg"
import pencil24 from "./basic/24/24_edit.svg"
import print from "./basic/24/icon_24_print.svg"
import info from "./basic/24/icon_24_info.svg"
import download24 from "./basic/24/icon_24_download.svg"
import attachments from "./basic/24/icon_20_attachment.svg"
import single_image from "./basic/24/icon_20_image.svg"
import table from "./basic/24/icon_20_table.svg"
import text from "./basic/24/icon_20_text.svg"
import plus24 from "./basic/24/24_add.svg"
import view_line from "./basic/24/icon_20_view 2.svg"
import vied_items from "./basic/24/icon_20_view 1.svg"
import x_icon_24 from "./basic/24/State=enabled (6).svg"
import arrow_left_24 from "./basic/24/24_arrow_back.svg"
import arrow_right_24 from "./basic/24/24_arrow_forward.svg"
import hand from "./basic/24/24_hand.svg"
import like_gray from "./gray/24/24_like.svg"
import comment from "./gray/24/icon_24_comments.svg"
import checkbox_empty from "./basic/24/State=enabled (7).svg"
import checkbox_filled from "./theme_color/24/State=pressed (1).svg"
import checkbox_filledegk from "./theme_color/24/State=pressed egk.svg"
import flag_empty from "./gray/24/24_bookmark.svg"
import flag_filled from "./basic/24/24_bookmark_filled.svg"
import flag_filledzdi from "./basic/24/24_bookmark_filled.svg"
import flag_filledegk from "./basic/24/24_bookmark_filled_egk.svg"
import reduced_arrow from "./basic/24/arrow_back_ios.svg"
import eye_opened_icon from "./gray/24/24_visibility.svg"
import eye_closed_icon from "./gray/24/24_visibility_off.svg"
import light_gray_print from "./gray/24/icon_24_print (1).svg"
import light_gray_edit from "./gray/24/24_edit.svg"
import share_icon_zdi from "./theme_color/24/share_icon_ukhd.svg"
import share_icon_egk from "./theme_color/24/24_share_egk.svg"
import gray_backa_arrow from "./gray/24/20_back.svg"
import white_arrow from "./white/24/24_arrow_forward.svg"
import plus_button_blue from "./theme_color/24/20_add.svg"
import user_24_light from "./white/24/user_image_light.svg"
//40
import login from "./basic/40/40_login.svg"
import user_icon from "./basic/40/40_account_circle.svg"
import error from "./basic/40/40_error.svg"
import image_mode from "./basic/40/icon_40_imagesmode.svg"
import info_icon from "./gray/40/40_error.svg"
//64
import download64 from "./gray/64/icon_48_download.svg"
import upload64 from "./gray/64/icon_48_upload.svg"
//other
import userImage from "./other/icon_dark.svg"
import default_user_form_image  from "./other/Avatar.png"
import load_images from "./other/Image.png"

export default {
    pancil,
    tresh_box,
    plus,
    arrow_down,
    arrow_up,
    arrow_left,
    arrow_right,
    download,
    copy,
    long_arrow_down,
    long_arrow_up,
    like,
    like_hover,
    v_icon,
    x_icon,
    smile,
    doggy,
    linkage,
    image_list,
    eye,
    eye_closed,
    search,
    pencil24,
    print,
    info,
    download24,
    attachments,
    single_image,
    table,
    text,
    plus24,
    view_line,
    vied_items,
    x_icon_24,
    arrow_left_24,
    arrow_right_24,
    hand,
    like_gray,
    comment,
    checkbox_empty,
    checkbox_filled,
    checkbox_filledegk,
    flag_empty,
    flag_filled,
    flag_filledzdi,
    flag_filledegk,
    reduced_arrow,
    login,
    user_icon,
    error,
    image_mode,
    download64,
    upload64,
    arrow_right_white,
    userImage,
    info_icon,
    edit_ukhd,
    edit_egk,
    tresh_box_blue,
    eye_opened_icon,
    eye_closed_icon,
    default_user_form_image,
    light_gray_print,
    light_gray_edit,
    share_icon_zdi,
    like_button,
    gray_backa_arrow,
    white_arrow,
    plus_button_blue,
    trash_icon_ukhd,
    share_icon_egk,
    trash_icon_egk,
    like_button_ukhd,
    like_button_egk,
    load_images,
    user_24_light
}