import { useDispatch } from "react-redux";
import { show, hide, setData, setPosition } from "../../features/popup.hint";
import constants from "./constants";

const useFillHint = (description = "") => {
  const dispatcher = useDispatch();


  const setHintValue = (hintElement)=>{
    if(hintElement){
      const span = hintElement.querySelector('span');
      if(span){
        span.innerHTML = description;
      }
    }
  }

  const setHintPosition = (element, hintElement) => {
    setHintValue(hintElement);
    if (element && hintElement) {
      const hintElementSize = hintElement.getBoundingClientRect();
      const top = document.documentElement.scrollTop
      const coords = {
        x: element.left + (element.width / 2 - hintElementSize.width/2),
        y: element.top + element.height + constants.TOP_MARGIN + top,
      };

      dispatcher(setPosition(coords));
    }
  }

  const mouseOver = (e) => {
    if (description) {
      dispatcher(setData(description));
      
      const element = e.target.getBoundingClientRect();
      const hintElement = document.querySelector(`#icon-hint`);
      
      setHintValue(hintElement)
      setHintPosition(element, hintElement);

      dispatcher(show());
    }
  };



  const mouseLeave = () => {
    dispatcher(hide());
    dispatcher(setPosition({ x: 0, y: 0 }));
  };

  return {
    mouseOver,
    mouseLeave,
  };
};

export default useFillHint;
